body {
  margin: 0;
  font-family: "Arial", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dbdbdb;
}

.page {
  min-height: 100vh;
  padding-top: 56px;
  padding-bottom: 56px;
}

a {
  text-decoration: none;
}
a.active {
  text-decoration: underline;
}

.input-div {
  max-width: 500px;
}

textarea {
  resize: none;
  min-height: 100px !important;
  max-width: 1032px;
}

.add-more-btn {
  cursor: pointer;
  max-width: 300px;
  width: 100%;
  margin: 10px auto !important;
  padding: 10px;
}

.card {
  background-color: #ededed;
  padding: 10px;
  margin: 10px 0;
}

.de-btn {
  border: none;
  outline: none;
}

.floating {
  position: fixed !important;
  bottom: 0;
  right: 0;
  z-index: 2000;
  width: 200px !important;
}

.pdf-viwer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 50px;
}

.About {
  padding-top: 60px;
  min-height: 100vh;
}

.About img {
  width: 32px;
  height: 32px;
}

.shoutOut {
  text-align: center;
  justify-self: flex-end;
  bottom: 0;
  margin-top: 50px;
}

.shoutOut p {
  margin: 0;
}

@media only screen and (max-width: 570px) {
  #pd .input-div {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
